











































































































































































































































































































































































































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { mapStores } from "pinia";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachappDoelFrequentie,
	IomodelsCoachappLidInfo,
	IomodelsCoachappLidMetPrioInfo,
} from "@/lib/backend/club.api";
import type { IomodelsLid } from "@/lib/backend/reserveer.api";
import { GESLACHT_MAP } from "@/lib/constants/options";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { GeslachtSchema } from "@/lib/schemas";
import { logger } from "@/logger";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import { CoachService } from "@/services/coach";
import { getDateFromDatum, getDayMonthYear } from "@/utils/date";

type Data = {
	loading: boolean;
	editing: boolean;
	profileImage: {
		loading: boolean;
		id: string;
		newProfileImage: string;
		imageShowing?: string;
		file?: Blob;
	};
	GESLACHT_MAP: typeof GESLACHT_MAP;
	formData: {
		voornaam: string;
		achternaam: string;
		geslacht: IomodelsCoachappLidInfo["geslacht"];
		onboardingAfgerond: boolean;
		plaats: string;
		postcode: string;
		adres: string;
		email: string;
		telefoon: string;
		telefoonMobiel: string;
		geboortedatum: string;
		coachingsGroep: IomodelsLid["coachingsGroep"];
		coachLid: number;
		doelFrequentie: number;
	};
};

export default defineComponent({
	name: "PfgGebruikerPersoonlijkeGegevens",
	setup() {
		const { id: gymId } = useCurrentGym();
		const gebruikerStore = useGebruikerStore();

		const { data: gyms } = useQuery(queryOptions_gyms());
		const { data: coaches } = useQuery(queryOptions_coaches());

		const filteredGyms = computed(() =>
			gyms.value?.filter(
				(gym) =>
					gym.id === gymId.value ||
					coaches.value
						?.find((coach) => coach.id === gebruikerStore.lidInfo.coachLid)
						?.gymIds.includes(gym.id),
			),
		);

		const filteredCoaches = computed(() =>
			coaches.value?.filter(
				(coach) =>
					coach.gymIds.includes(gymId.value) || coach.id === gebruikerStore.lidInfo.coachLid,
			),
		);

		return { getFullName: getCoachName, gyms: filteredGyms, coaches: filteredCoaches };
	},
	data(): Data {
		return {
			loading: false,
			editing: false,
			profileImage: {
				loading: false,
				id: "",
				newProfileImage: "",
				imageShowing: undefined,
				file: undefined,
			},
			GESLACHT_MAP,
			formData: {
				voornaam: "",
				achternaam: "",
				geslacht: "",
				onboardingAfgerond: false,
				plaats: "",
				postcode: "",
				adres: "",
				email: "",
				telefoon: "",
				telefoonMobiel: "",
				geboortedatum: "",
				coachingsGroep: undefined,
				coachLid: undefined as unknown as number,
				doelFrequentie: undefined as unknown as number,
			},
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
		isPhoneRequired(): boolean {
			if (!this.formData.telefoonMobiel) return true;
			else return false;
		},
		isPhoneMobileRequired(): boolean {
			if (!this.formData.telefoon) return true;
			else return false;
		},
	},
	watch: {
		$route: "check",
		"gebruikerStore.lidInfo"(newValue: IomodelsCoachappLidInfo) {
			this.formData.voornaam = newValue.voornaam;
			this.formData.achternaam = newValue.achternaam;
			this.formData.geslacht =
				newValue.geslacht === "man" ? "m" : newValue.geslacht === "vrouw" ? "f" : "";
			if (newValue.geboortedatum)
				this.formData.geboortedatum = getDayMonthYear(
					new Date(newValue.geboortedatum ?? ""),
					"short",
				);
			this.formData.adres = newValue.adres ?? "";
			this.formData.postcode = newValue.postcode?.replace(/\s/g, "") ?? "";
			this.formData.plaats = newValue.stad ?? "";
			this.formData.telefoon = newValue.telefoon ?? "";
			this.formData.telefoonMobiel = newValue.telefoonMobiel ?? "";
			this.formData.email = newValue.email ?? "";
			this.formData.coachLid = newValue.coachLid as unknown as number;
			this.formData.coachingsGroep = newValue.coachingsGroep;
			this.formData.onboardingAfgerond = newValue.onboardingAfgerond;
		},
		"gebruikerStore.doelFrequentie"(newValue: IomodelsCoachappDoelFrequentie) {
			if (newValue.doelFrequentieOpgegeven && newValue.doelfrequentie)
				this.formData.doelFrequentie = newValue.doelfrequentie;
		},
		"gebruikerStore.basicInfo"(newValue: IomodelsCoachappLidMetPrioInfo) {
			if (newValue?.profileImg) {
				this.resetImage();
				this.profileImage.imageShowing = newValue.profileImg;
			}
		},
	},
	async created() {
		await this.check();
	},
	async destroyed() {
		this.editing = false;
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getDashboardLidInfo();
			await this.gebruikerStore.getDoelFrequentie();
		},
		async cancelEdit() {
			this.editing = false;

			await this.getRequiredInfo();

			this.errors.clear();
		},
		async saveEdit() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				const errors = this.$validator.getErrors();
				logger.log(errors); // Access the validation errors object
			}

			const resultGeslacht = GeslachtSchema.safeParse(this.formData.geslacht);

			if (!isValid || !resultGeslacht.success) return false;

			await this.gebruikerStore.saveDashboardLidInfo({
				lidId: this.gebruikerStore.id,
				voornaam: this.formData.voornaam,
				achternaam: this.formData.achternaam,
				adres: this.formData.adres,
				email: this.formData.email,
				geboortedatum: getDateFromDatum(this.formData.geboortedatum ?? "").toISOString(),
				geslacht: resultGeslacht.data,
				plaats: this.formData.plaats,
				postcode: this.formData.postcode.replace(/\s/g, ""),
				doelFrequentie: this.formData.doelFrequentie ?? 0,
				telefoon: this.formData.telefoon,
				telefoonMobiel: this.formData.telefoonMobiel,
			});

			if (this.formData.coachLid)
				await clubApi.lid.coachVoorLidWijzigen(this.gebruikerStore.id, this.formData.coachLid);
			else await clubApi.lid.coachVoorLidVerwijderen(this.gebruikerStore.id);

			if (this.formData.coachingsGroep)
				await clubApi.lid.groepVoorLidWijzigen(
					this.gebruikerStore.id,
					encodeURIComponent(this.formData.coachingsGroep),
				);

			this.cancelEdit();
		},
		resetImage() {
			this.profileImage = {
				loading: false,
				id: undefined as unknown as string,
				newProfileImage: undefined as unknown as string,
				imageShowing: undefined,
				file: undefined,
			};
		},
		async saveImage() {
			if (!this.profileImage.file) return logger.error("No profile image selected");

			this.profileImage.loading = true;

			try {
				await new CoachService().updateLidProfileImage(
					this.gebruikerStore.id,
					this.profileImage.file,
				);

				this.popupStore.open({
					title: "Gelukt!",
					body: "De profielafbeelding is succesvol opgeslagen",
					buttons: {
						confirm: "Terug naar lid",
					},
					callback: async () => {
						await this.gebruikerStore.getBasicInfo();
						await this.gebruikerStore.getDashboardLidInfo();
					},
				});
			} catch (error) {
				this.popupStore.showError(
					`Er ging iets mis bij het opslaan van de profiel afbeelding ${error}. Probeer het later nog eens.`,
				);
			}

			this.profileImage.loading = false;
		},
	},
});
